@import url(https://rsms.me/inter/inter.css);
html {
  font-family: 'Inter', sans-serif;
  -webkit-font-feature-settings: 'zero', 'ss01', 'ss02', 'ss03';
          font-feature-settings: 'zero', 'ss01', 'ss02', 'ss03';
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

header {
  /* background-color: #212121; */
  padding: 48px;
  width: 100%;
}

footer {
  margin: 0 0 24px 0;
}

code {
  /* background-color: #303030; */
  padding: 0 16px;
  border-radius: 16px;
  white-space: nowrap;
}

hr {
  margin: 32px;
}

.form {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  margin: 56px 0 8px;
}

.submitBox {
  margin-bottom: 25.15px;
  display: flex;
}

.appBar {
  align-items: center;
}

.tabs {
}

.tabs button {
  border-radius: 16px 16px 0 0;
}

main.tabsWrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 24px 24px;
  border-radius: 0 0 16px 16px;
}

.tabContainer {
  padding: 24px;
}

.tabContainer h2 {
  margin: 20px 0;
}

.searchFormControl {
  width: 100%;
}

.MuiInputLabel-filled.MuiInputLabel-shrink.inputShrink {
  -webkit-transform: translate(12px, 10px) scale(0.55);
          transform: translate(12px, 10px) scale(0.55);
}

.selection {
  display: flex;
  justify-content: space-evenly;
  margin: 52px 0;
  grid-gap: 8px;
  gap: 8px;
}

.selection > * {
  width: 33%;
  max-width: 150px;
}

.resultCard {
  margin-bottom: 20px;
  max-width: 700px;
}

.resultDatasource {
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
}

div.resultCardHeader {
  text-align: left;
  text-transform: lowercase;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
  padding: 16px 16px 8px;
}

.resultCardHeaderTitle {
  display: flex;
  justify-content: center;
  align-items: baseline;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 8px;
}

.resultCardActions {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.multiResultCardContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.link {
  text-decoration: none;
  color: inherit;
  box-shadow: inset 0 -3px 0 #ff9800;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

.link:hover {
  /* color: #212121; */
  box-shadow: inset 0 -1.3rem 0 #ff9800;
}

.footerLink {
  text-decoration: none;
  color: inherit;
  box-shadow: inset 0 0 0 #ff9800;
  transition: all 300ms ease-in-out;
}

.footerLink:hover {
  /* color: #212121; */
  box-shadow: inset 0 -1.3rem 0 #ff9800;
}

.buttonIcon {
  margin-left: 8px;
}

.snackbarCloseIcon {
  padding: 4px;
}

.cursorCopy {
  cursor: copy;
}

@media (max-width: 600px) {
  .tabs {
    width: 100%;
    max-width: 480px;
  }
}

.book-list > div {
  display: flex;
}

.book-list > div > div {
  flex: 1 1;
  text-align: left;
  font-size: 18px;
}

.book-list > div > div > span {
  cursor: pointer;
}

blockquote {
  font-style: italic;
}

.about {
  font-size: 18px;
}

.datasources > div {
  display: flex;
  align-items: center;
}
